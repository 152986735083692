export const SYNC_REQUESTED = "SYNC_REQUESTED";
export const SYNC_SUCCESS = "SYNC_SUCCESS";
export const SYNC_FAILED = "SYNC_FAILED";

// SYNC MODAL
export const SET_SYNC_MODAL_REQUESTED = "SET_SYNC_MODAL_REQUESTED";

export const GET_SLAVE_MACHINES_REQUESTED = "GET_SLAVE_MACHINES_REQUESTED";
export const GET_SLAVE_MACHINES_SUCCESS = "GET_SLAVE_MACHINES_SUCCESS";
export const GET_SLAVE_MACHINES_FAILED = "GET_SLAVE_MACHINES_FAILED";
