import { call, put, debounce } from "redux-saga/effects";
import instance, { nodeInstance } from "../../../api/api_instance";

async function getApi(data) {
  const search = data.search.replace(/\s+/g, " ").trim();
  try {
    const result = nodeInstance({
      url: `v1/model/${data.model_id}/error_codes/2?search=${encodeURIComponent(
        search,
      )}&limit=${data.limit}&page=${data.page + 1}&sort_column=${
        data.sorting
      }&paginate=${data.paginate}&sort_order=${
        data.sort == 1 ? "asc" : data.sort == 2 ? "desc" : ""
      }&filters=${JSON.stringify(data.filter)}`,
      method: "GET",
    }).then((response) => {
      return response;
    });
    return await result;
  } catch (error) {
    throw error;
  }
}

function* fetchmCodes(action) {
  try {
    const res = yield call(getApi, action.payload);
    yield put({ type: "GET_ALL_MCODES_SUCCESS", mCodesList: res.data });
  } catch (e) {
    yield put({
      type: "GET_ALL_MCODES_FAILED",
      message: e.response.data.errors,
    });
  }
}

function* mCodesSaga() {
  yield debounce(1000, "GET_ALL_MCODES_REQUESTED", fetchmCodes);
}

export default mCodesSaga;
