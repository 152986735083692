import { call, put, takeEvery } from "redux-saga/effects";
import { nodeInstance } from "../../../api/api_instance";

async function getApi(data) {
  try {
    const result = nodeInstance({
      url: `v1/model/${data.model_id}/anaglyph/${data.id}`,
      method: "DELETE",
    }).then((response) => {
      return response;
    });
    return await result;
  } catch (error) {
    throw error;
  }
}

function* deleteAnaglyph(action) {
  const data = {
    model_id: action.payload.model_id,
    search: "",
    page: 0,
    limit: 10,
    sort: "",
    sorting: "",
  };

  try {
    const res = yield call(getApi, action.payload);
    // window.location.href = window.location.pathname;
    yield put({ type: "DELETE_ANAGLYPH_SUCCESS", anaglyphList: res.data });
    yield put({ type: "GET_ALL_ANAGLYPH_REQUESTED", payload: data });
    const toastrData = {
      content: `${res.data.anaglyph.title} 3D and 3D Parts deleted Successfully`,
      type: "success",
    };
    yield put({ type: "SET_TOASTER_SUCCESS", data: toastrData });
  } catch (e) {
    const toastrData = {
      content: "Failed to delete this 3D",
      type: "failed",
    };
    if (e.response.status === 406 || e.response.status === 404) {
      yield put({ type: "DELETE_ANAGLYPH_FAILED", message: e.response.data });
      yield put({ type: "SET_TOASTER_SUCCESS", data: toastrData });
    } else {
      yield put({
        type: "DELETE_ANAGLYPH_FAILED",
        message: "Some error occurred",
      });
      yield put({ type: "SET_TOASTER_SUCCESS", data: toastrData });
    }

    if (e.response.status === 500) {
      const toastrFailedData = {
        content: e.response.data.errors
          ? e.response.data.errors
          : "Something went wrong!",
        type: "failed",
      };
      yield put({ type: "SET_TOASTER_SUCCESS", data: toastrFailedData });
    }
  }
}

function* deleteAnaglyphSaga() {
  yield takeEvery("DELETE_ANAGLYPH_REQUESTED", deleteAnaglyph);
}

export default deleteAnaglyphSaga;
