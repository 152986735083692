import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    admin: "true",
    // timeout : 1000,
  },
});

export const nodeInstance = axios.create({
  baseURL: process.env.REACT_APP_NODE_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    admin: "true",
    // timeout : 1000,
  },
});

export default instance;
