export const FileTypes = {
  image: "image",
  pdf: "pdf",
  "3dZip": "3d-zip",
  video: "video",
};

export const EnvironmentConstant = {
  mode: process.env.REACT_APP_MODE,
  machine: process.env.REACT_APP_MACHINE,
  client: process.env.REACT_APP_CLIENT,
};
