import { call, put, takeEvery } from "redux-saga/effects";
import instance, { nodeInstance } from "../../../api/api_instance";

async function getApi(data) {
  try {
    const result = nodeInstance({
      url: `/v1/category/secondary?parent_id=${data}`,
      method: "GET",
    }).then((response) => {
      return response;
    });
    return await result;
  } catch (error) {
    throw error;
  }
}

function* fetchAllCategories(action) {
  try {
    const res = yield call(getApi, action.payload);
    yield put({
      type: "GET_SECONDARY_CATEGORIES_IN_DROPDOWN_SUCCESS",
      secondaryCategories: res.data,
    });
  } catch (e) {
    yield put({
      type: "GET_SECONDARY_CATEGORIES_IN_DROPDOWN_FAILED",
      message: e.message,
    });
  }
}

function* secondaryCategoriesInDropDownSaga() {
  yield takeEvery(
    "GET_SECONDARY_CATEGORIES_IN_DROPDOWN_REQUESTED",
    fetchAllCategories,
  );
}

export default secondaryCategoriesInDropDownSaga;
