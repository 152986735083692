import { call, put, takeEvery } from "redux-saga/effects";
import { nodeInstance } from "../../../api/api_instance";

async function getApi(data) {
  const postData = {
    name: data.name,
    steps_attributes: data.steps_attributes,
  };

  try {
    const result = nodeInstance({
      url: `v1/model/${data.model_id}/procedure/${data.procedure_id}`,
      method: "PUT",
      data: postData,
    }).then((response) => {
      return response;
    });
    return await result;
  } catch (error) {
    throw error;
  }
}

function* procedure(action) {
  const data = {
    model_id: action.payload.model_id,
    procedure_id: action.payload.procedure_id,
    search: "",
    page: 0,
    limit: 10,
    filter: {},
    sort: false,
    sorting: "",
  };
  const allProcedureData = {
    search: "",
    page: 0,
    limit: 10,
    filter: {},
    sort: false,
    sorting: "",
  };
  const errorCodeData = {
    error_id: action.payload.error_id,
    search: "",
    page: 0,
    limit: 10,
    sort: false,
    sorting: "",
    filter: {},
  };

  try {
    const res = yield call(getApi, action.payload);
    const toastrData = {
      content: `${res.data.procedure.name} Procedure Step details updated Successfully`,
      type: "success",
    };
    yield put({ type: "UPDATE_PROCEDURE_STEP_SUCCESS", deviceData: res.data });
    yield put({ type: "PROCEDURE_DETAILS_REQUESTED", payload: data });
    yield put({ type: "SET_TOASTER_SUCCESS", data: toastrData });
    if (window.location.pathname == "/procedures") {
      yield put({ type: "ALL_PROCEDURE_REQUESTED", payload: allProcedureData });
    } else if (window.location.pathname == "/device-model") {
      if (action.payload.callingFrom == "error_code") {
        yield put({
          type: "GET_ALL_PROCEDURE_LINKED_TO_ERROR_REQUESTED",
          payload: errorCodeData,
        });
      } else {
        yield put({ type: "GET_MODEL_PROCEDURE_REQUESTED", payload: data });
      }
    } else {
    }
  } catch (e) {
    if (e.response.status === 406 || e.response.status === 404) {
      yield put({
        type: "UPDATE_PROCEDURE_STEP_FAILED",
        message: e.response.data.message,
      });
    } else {
      yield put({
        type: "UPDATE_PROCEDURE_STEP_FAILED",
        message: "Some error occurred",
      });
    }
    if (e.response.status === 500) {
      const toastrFailedData = {
        content: e.response.data.errors
          ? e.response.data.errors
          : "Something went wrong!",
        type: "failed",
      };
      yield put({ type: "SET_TOASTER_SUCCESS", data: toastrFailedData });
    }
  }
}

function* updateProcedureStepSaga() {
  yield takeEvery("UPDATE_PROCEDURE_STEP_REQUESTED", procedure);
}
export default updateProcedureStepSaga;
